import cx from 'classnames';

import { MDText } from 'i18n-react';

import { Content } from '@motorway/motorway-storybook-cra';

import {
  HOMEPAGE_GA_TAGS,
  HOMEPAGE_SECTIONS,
} from 'Utilities/analytics/analyticsHomePage';

import aa from '../../../../../../client/assets_seller/partners/aa.svg';
import confused from '../../../../../../client/assets_seller/partners/confused.svg';
import goCompare from '../../../../../../client/assets_seller/partners/goCompare.svg';
import moneySuperMarket from '../../../../../../client/assets_seller/partners/moneySuperMarket.svg';
import octopus from '../../../../../../client/assets_seller/partners/octopusNew.svg';
import { usePageRefEvents } from '../../../common/Hooks/usePageRefEvents';
import LocalTexts from '../../HomepageTexts.json';

import styles from './HomepagePartnerLogos.scss';

const LocalT = new MDText(LocalTexts);

const t = (key: string) => LocalT.translate(key) as string;

const TEXT = {
  aa: t('partner.altTexts.aa'),
  confused: t('partner.altTexts.confused'),
  goCompare: t('partner.altTexts.goCompare'),
  moneySuperMarket: t('partner.altTexts.moneySuperMarket'),
  octopus: t('partner.altTexts.octopus'),
  title: t('partner.title'),
};

type Props = {
  readonly className?: string;
}

export const HomepagePartnerLogos = ({ className }: Props) => {
  const onRefTrigger = () =>
    HOMEPAGE_GA_TAGS.SECTION_LOADED(HOMEPAGE_SECTIONS.RECOMMENDED_BY);
  const { setRef } = usePageRefEvents({ onRefTrigger });

  const partnerLogos = [
    { alt: TEXT.aa, height: 38.99, id: 1, src: aa, width: 36.55 },
    { alt: TEXT.octopus, height: 38.99, id: 2, src: octopus, width: 107.21 },
    { alt: TEXT.confused, height: 38.99, id: 3, src: confused, width: 141.36 },
    {
      alt: TEXT.moneySuperMarket,
      height: 38.99,
      id: 4,
      src: moneySuperMarket,
      width: 104.77,
    },
    {
      alt: TEXT.goCompare,
      height: 38.99,
      id: 5,
      src: goCompare,
      width: 149.85,
    },
  ];

  return (
    <Content
      className={cx(styles.component, className)}
    >
      <h3>{TEXT.title}</h3>
      <div
        ref={(ref) => setRef(ref)}
        className={styles.logos}
        data-testid="partnerLogos"
      >
        {partnerLogos.map(({ alt, height, id, src, width }) => (
          <span key={id} className={cx(styles.logo, styles[`logo${id}`])}>
            <img
              alt={alt}
              height={height}
              loading="lazy"
              src={src}
              width={width}
            />
          </span>
        ))}
      </div>
    </Content>
  );
};

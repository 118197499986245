import React from 'react';

import { MDText } from 'i18n-react';

import LocalTexts from '../../../../HomepageTexts.json';

import styles from './HeroContent.scss';

const LocalT = new MDText(LocalTexts);
const t = (key: string, props?: { [key:string]: unknown }) => LocalT.translate(key, props) as string;

export const TEXTS = {
  subTitle: t('hero.subTitle', {
    boldText: <span className={styles.boldText}>{t('hero.boldText')}</span>,
    TnC: <sup className={styles.TnC}>*</sup>,
  }),
  title: t('hero.title', {
    highlighted: <span className={styles.highlighted}>{t('hero.highlighted')}</span>,
  }),
};

export const HeroContent = () => (
  <div className={styles.component}>
    <h1 className={styles.title}>
      {TEXTS.title}
    </h1>
    <div className={styles.heroSubTitleWrapper}>
      <p className={styles.description} data-cy="homepageHeroVRM">
        {TEXTS.subTitle}
      </p>
    </div>
  </div>
);

import React from 'react';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { Content } from '@motorway/motorway-storybook-cra';

import cypressIds from 'CypressId';

import { HOMEPAGE_GA_TAGS, HOMEPAGE_SECTIONS } from 'Utilities/analytics/analyticsHomePage';
import { applyCypressData } from 'Utilities/index';

import { usePageRefEvents } from '../../../common/Hooks/usePageRefEvents';
import LocalTexts from '../../HomepageTexts.json';
import { Heading } from '../Heading/Heading';

import { NewsAndToolsCard } from './SubComponents/NewsAndToolsCard/NewsAndToolsCard';
import { newsAndToolsTilesContent } from './NewsAndTools.helper';

import styles from './NewsAndTools.scss';

const LocalT = new MDText(LocalTexts);

const t = (key: string) => LocalT.translate(key) as string;

const texts = {
  subTitle: t('NewsAndTools.subTitle'),
  title: t('NewsAndTools.title'),
};

type Props = {
  readonly className?: string;
}

export const NewsAndTools = ({ className }: Props) => {
  const eventLabels = newsAndToolsTilesContent.map((card) => card.label);
  const onRefTrigger = (eventLabel?: string) => {
    HOMEPAGE_GA_TAGS.SECTION_LOADED(HOMEPAGE_SECTIONS.NEWS_AND_TOOLS, eventLabel);
  };
  const {
    $wrapper,
    isSectionVisible,
    setRef,
  } = usePageRefEvents({ eventLabels, onRefTrigger });

  const onTileClick = (eventLabel: string, link: string) => {
    HOMEPAGE_GA_TAGS.NEWS_AND_TOOLS_TILE_CLICKED(eventLabel, link);
  };

  return (
    <div className={cx(styles.componentWrapper, className)} {...applyCypressData(cypressIds.sections.newsAndTools)}>
      <Content className={styles.wrapperPaddingOverride}>
        <div className={styles.headingsWrapper}>
          <Heading
            subtitle={texts.subTitle}
            title={texts.title}
          />
        </div>

        <div className={styles.contentWrapper}>
          <section ref={$wrapper} className={styles.component} data-testid='newsAndTools'>
            {
              newsAndToolsTilesContent.map((tileContent, i) => (
                <NewsAndToolsCard
                  key={tileContent.label}
                  ref={(ref) => setRef(ref, i)}
                  {...{ isSectionVisible }}
                  onClick={() => onTileClick(tileContent.label, tileContent.trackingLink || tileContent.link)}
                  { ...tileContent } />
              ))
            }
          </section>
        </div>
      </Content>
    </div>
  );
};

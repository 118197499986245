import { MDText } from 'i18n-react';

import AnnabelAvif from '../../../../../../client/assets_seller/homepage/stories/annabel.avif';
import AnnabelPng from '../../../../../../client/assets_seller/homepage/stories/annabel.png';
import AnnabelWebp from '../../../../../../client/assets_seller/homepage/stories/annabel.webp';
import AnthoniaAvif from '../../../../../../client/assets_seller/homepage/stories/anthonia.avif';
import AnthoniaPng from '../../../../../../client/assets_seller/homepage/stories/anthonia.png';
import AnthoniaWebp from '../../../../../../client/assets_seller/homepage/stories/anthonia.webp';
import NigelAvif from '../../../../../../client/assets_seller/homepage/stories/nigel.avif';
import NigelPng from '../../../../../../client/assets_seller/homepage/stories/nigel.png';
import NigelWebp from '../../../../../../client/assets_seller/homepage/stories/nigel.webp';

import SellerStoriesTexts from './SellerStories.texts.json';

const LocalT = new MDText(SellerStoriesTexts);
const t = (key: string, props?: { [key:string]: unknown }) => LocalT.translate(key, props) as string;

export const sellerStoriesConfig = {
  highlightedWord: t('highlightedWord'),
  label: t('label'),
  stories: [
    {
      details: t('stories.0.details'),
      image: {
        avif: AnthoniaAvif,
        png: AnthoniaPng,
        webp: AnthoniaWebp,
      },
      label: t('label'),
      quote: t('stories.0.quote'),
    },
    {
      details: t('stories.1.details'),
      image: {
        avif: NigelAvif,
        png: NigelPng,
        webp: NigelWebp,
      },
      label: t('label'),
      quote: t('stories.1.quote'),
    },
    {
      details: t('stories.2.details'),
      image: {
        avif: AnnabelAvif,
        png: AnnabelPng,
        webp: AnnabelWebp,
      },
      label: t('label'),
      quote: t('stories.2.quote'),
    },
  ],
  subtitle: t('subtitle'),
  title: t('title'),
};

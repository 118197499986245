import React from 'react';

import { HOMEPAGE_GA_TAGS, HOMEPAGE_SECTIONS } from 'Utilities/analytics/analyticsHomePage';

import { Guides } from '../Guides/Guides';
import { GuideData } from '../Guides/Guides.types';

type Props = {
  readonly className?: string;
}

export const GuidesSection = ({ className }: Props) => {
  const onGuidesSectionLoad = (label: string) => HOMEPAGE_GA_TAGS.SECTION_LOADED(HOMEPAGE_SECTIONS.GUIDES, label);
  const onGuidesCTAClick = (path: string) => HOMEPAGE_GA_TAGS.GUIDES_EXPLORE_CLICKED(path);

  return (
    <Guides
      onCtaClick={(path: string) => onGuidesCTAClick(path)}
      onGuideClick={(guide: GuideData) => HOMEPAGE_GA_TAGS.GUIDE_CLICKED(guide)}
      onLoad={onGuidesSectionLoad}
      wrapperClassName={className}
    />
  );
};

import React, { forwardRef } from 'react';

import styles from './Step.scss';

export type StepProps = {
  description: string;
  image: {
    alt: string;
    avif: string;
    height: number;
    jpg: string;
    webp: string;
    width: number;
  };
  renderBadge: React.JSX.Element;
  title: string;
};

export const Step = forwardRef<HTMLDivElement, StepProps>(({ description, image, renderBadge, title }, ref) => (
  <div ref={ref} className={styles.component} data-testid='howItWorksStep'>
    <picture>
      <source srcSet={image.avif} type="image/avif" />
      <source srcSet={image.webp} type="image/webp" />
      <img alt={image.alt} height={image.height} loading="lazy" src={image.jpg} width={image.width} />
    </picture>
    <div className={styles.content}>
      <div className={styles.heading}>
        {renderBadge}
        <h3>{title}</h3>
      </div>
      <p>{description}</p>
    </div>
  </div>
));

import React, { useState } from 'react';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { Content } from '@motorway/motorway-storybook-cra';

import { HOMEPAGE_GA_TAGS, HOMEPAGE_SECTIONS } from 'Utilities/analytics/analyticsHomePage';

import TrustpilotReviewsCarousel from '../../../../../components/reviews/ReviewsCarousel/TrustpilotReviewsCarousel';
import { usePageRefEvents } from '../../../common/Hooks/usePageRefEvents';
import LocalTexts from '../../HomepageTexts.json';
import { Heading } from '../Heading/Heading';

import styles from './Reviews.scss';

const LocalT = new MDText(LocalTexts);

const t = (key: string) => LocalT.translate(key) as string;

const texts = {
  highlightedWord: t('reviews.highlightedWord'),
  title: t('reviews.title'),
};

type Props = {
  readonly className?: string;
}

export const Reviews = ({ className }: Props) => {
  const onRefTrigger = () => {
    HOMEPAGE_GA_TAGS.SECTION_LOADED(HOMEPAGE_SECTIONS.REVIEWS);
  };
  const { setRef } = usePageRefEvents({ onRefTrigger });
  const [failedLoad, setFailedLoad] = useState<boolean>(false);

  return (
    <>
      {
        !failedLoad
        && <div ref={(ref) => setRef(ref)} className={cx(styles.component, className)} data-testid='reviews'>
          <Content className={styles.headingWrapper}>
            <Heading
              highlightedWord={texts.highlightedWord}
              title={texts.title}
            />
          </Content>
          <TrustpilotReviewsCarousel {...{ failedLoad, setFailedLoad }} className={styles.trustpilot} />
        </div>
      }
    </>
  );
};

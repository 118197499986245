import React, {
  useEffect,
  useRef,
} from 'react';
import cx from 'classnames';

import { HOMEPAGE_GA_TAGS, HOMEPAGE_SECTIONS } from 'Utilities/analytics/analyticsHomePage';

import VehicleImage from '../../../../../components/misc/VehicleImage/VehicleImage';
import { HeroFrameAnimation } from '../../../common/Hero/HeroFrameAnimation/HeroFrameAnimation';
import { useHeroHelpers } from '../../../common/Hero/Hooks/useHeroHelpers';
import ChevronAnimation from '../../../fastlane/chevronAnimation/ChevronAnimation';

import { HomepageVRM } from './SubComponents/HomepageVRM/HomepageVRM';
import { StaticConfetti } from './SubComponents/StaticConfetti/StaticConfetti';

import styles from './Hero.scss';

export type HeroProps = {
  subTitle?: string;
  title?: string;
};

export const Hero = ({ subTitle, title }: HeroProps) => {
  const container = useRef<HTMLInputElement>(null);

  const {
    cssVars,
    isAnimatingMileageTransition,
    isAnimatingVrmTransition,
    isMileageTransition,
    loading,
    loadingHandler,
    minHeight,
    prevIsAnimatingMileageTransition,
    setIsAnimatingMileageTransition,
    setIsAnimatingVrmTransition,
  } = useHeroHelpers({ animateFromHomePage: true, container });

  useEffect(() => HOMEPAGE_GA_TAGS.SECTION_LOADED(HOMEPAGE_SECTIONS.HERO), []);

  const mileageTransitionStyles = {
    [styles.mileageTransition]: prevIsAnimatingMileageTransition || isMileageTransition,
  };

  return (
    <div className={cx(styles.homepageHeroWrapper, {
      mileageTransitionStyles,
    })}>
      <div
        className={styles.homepageVehicles}>
        <VehicleImage
          className={cx(
            styles.homepageVehicleSilver,
            styles.vehicleImage,
            mileageTransitionStyles,
          )}
          name="next_homepage_white"
        />
        <VehicleImage
          className={cx(
            styles.homepageVehicleBlack,
            styles.vehicleImage,
            mileageTransitionStyles,
          )}
          name="next_homepage_black"
        />
      </div>

      <StaticConfetti
        inProgress={isAnimatingVrmTransition || isAnimatingMileageTransition || Boolean(loading)} />

      <div
        className={styles.homepageHero}
      >
        {isMileageTransition && (
          <HeroFrameAnimation
            isAnimating={isAnimatingMileageTransition}
            setIsAnimating={setIsAnimatingMileageTransition}
          />
        )}

        <div className={styles.homepageContentWrapper}>
          <ChevronAnimation
            isUpwardsVariant
            isTransition={isMileageTransition}
            loading={isAnimatingVrmTransition || isAnimatingMileageTransition}
            setAnimationInProgress={setIsAnimatingVrmTransition}
          />
          <div
            className={styles.component}
            style={{ ['--hero-min-height' as string]: minHeight }}
          >
            <div ref={container} style={cssVars}>
              <HomepageVRM
                {...{
                  loadingHandler,
                  subTitle,
                  title,
                }}
                inProgress={isAnimatingVrmTransition || isAnimatingMileageTransition || Boolean(loading)}
              />
            </div>
          </div>
          <div className={cx(styles.visualWrapper, { mileageTransitionStyles })}></div>
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useState } from 'react';
import cx from 'classnames';

import desktopConfettiBottom from '../../../../../../../../client/assets_seller/homepage/confetti-layer-bottom.svg';
import desktopConfettiTop from '../../../../../../../../client/assets_seller/homepage/confetti-layer-top.svg';

import breakpoints from '../../../../../../../components/misc/Breakpoints/Breakpoints.scss';
import styles from './StaticConfetti.scss';

type StaticConfettiProps = {
  inProgress: boolean;
};

export const StaticConfetti = ({ inProgress }: StaticConfettiProps) => {
  const [fallback, setFallback] = useState(false);

  useEffect(() => {
    const cssHasSupport = globalThis?.CSS?.supports?.('selector(:has(*))') ?? false;
    if (!cssHasSupport) {
      setFallback(true);
    }
  }, []);

  const imgProps: HTMLImageElement = {
    alt: 'confetti',
    fetchpriority: 'high',
    height: 1,
    // @ts-ignore
    onload: '(function(e) { e.parentNode.classList.add("confetti-loaded") })(this)',
    src: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAAtJREFUGFdjYAACAAAFAAGq1chRAAAAAElFTkSuQmCC',
    width: 1,
  };

  const imgPropsString = Object.entries(imgProps).reduce((acc, [key, value]) => `${acc} ${key}='${value}'`, '');

  const generateImage = () => ({
    __html: `
    <picture>
      <source height='212' media='(min-width: ${breakpoints.breakpointTablet})' srcSet='${desktopConfettiTop}' width='847' />
      <img ${imgPropsString} />
    </picture>
    <picture>
      <source height='168' media='(min-width: ${breakpoints.breakpointTablet})' srcSet='${desktopConfettiBottom}' width='555' />
      <img ${imgPropsString} />
    </picture>
    `,
  });

  return (
    <div
      dangerouslySetInnerHTML={generateImage()}
      suppressHydrationWarning
      className={cx(styles.confettiContainer, {
        [styles.fadeIn]: fallback,
        [styles.fadeOut]: inProgress,
      })}
    />
  );
};

import { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { ArrowRightIcon, Badge } from '@motorway/mw-highway-code';
import { Hyperlink } from '@motorway/mw-highway-code/alpha';
import { BUTTON_AS_TYPE, SIZE, VARIANT } from '@motorway/mw-highway-code/enums';

import { NEWS_AND_TOOLS_TILES } from '../../NewsAndTools.helper';

import styles from './NewsAndToolsCard.scss';

export type NewsAndToolsCardProps = {
  badge?: string;
  cta: string;
  img?: {
    alt: string;
    isBackgroundImage?: boolean;
    size: {
      height: `${number}%` | number;
      width: `${number}%` | number;
    };
    src: {
      avif: string;
      png: string;
      webp: string;
    };
  };
  isSectionVisible: boolean;
  label: string;
  link: string;
  onClick: () => void;
  openInNewTab?: boolean;
  title: string;
  trackingLink?: string;
  readonly type: typeof NEWS_AND_TOOLS_TILES[keyof typeof NEWS_AND_TOOLS_TILES];
};

type NewsAndToolsContentWrapperProps = {
  children: React.ReactNode;
  className: string;
  rel: string;
  target: string;
}

const isExternalLink = (r: string) => /^https?:\/\//.test(r);

const NewsAndToolsContentWrapper = ({
  children,
  className,
  link,
  onClick,
  rel,
  target,
}: Pick<NewsAndToolsCardProps, 'link' | 'onClick'> & NewsAndToolsContentWrapperProps) => (<>
  {
    isExternalLink(link)
      ? (
        <a
          {...{ className, onClick, rel, target }}
          href={link}
        >
          {children}
        </a>
      ) : (
        <Link
          {...{ className, onClick, rel, target }}
          to={link}
        >
          {children}
        </Link>
      )
  }
</>);

export const NewsAndToolsCard = forwardRef<HTMLDivElement, NewsAndToolsCardProps>(
  ({ badge: badgeText, cta, img, isSectionVisible, link, onClick, openInNewTab, title, type }, ref) => (
    <div ref={ref} data-testid='newsAndToolsCard'>
      <NewsAndToolsContentWrapper
        className={cx(styles.component, styles[type], {
          [styles.hasBackgroundImage]: img?.isBackgroundImage,
          [styles.loadBackgroundImage]: isSectionVisible,
        })}
        rel='noreferrer noopener'
        target={openInNewTab ? '_blank' : '_self'}
        {...{ link, onClick, openInNewTab }}
      >
        {img && (
          <picture className={cx({
            [styles.inlineImage]: !img?.isBackgroundImage,
          })}>
            <source srcSet={img?.src.avif} type="image/avif" />
            <source srcSet={img?.src.webp} type="image/webp" />
            <img
              alt={img?.alt}
              height={img?.size.height}
              loading='lazy'
              src={img?.src.png}
              width={img?.size.width} />
          </picture>
        )}
        <div className={styles.content}>
          <h3 className={styles.title}>
            <span>{title}</span>
            {badgeText ? <Badge data-thc-badge text={badgeText} variant="primary" /> : null}
          </h3>
          <div className={styles.hyperlink}>
            <Hyperlink as={BUTTON_AS_TYPE.BUTTON} label={cta} size={SIZE.MEDIUM} variant={VARIANT.SECONDARY} />
            <ArrowRightIcon size={SIZE.XSMALL} />
          </div>
        </div>
      </NewsAndToolsContentWrapper>
    </div>
  ),
);

import React from 'react';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { Content } from '@motorway/motorway-storybook-cra';
import { Button } from '@motorway/mw-highway-code';
import { BUTTON_AS_TYPE, VARIANT } from '@motorway/mw-highway-code/enums';

import { wordpressImgToImgixURL } from 'Utilities';
import { HOMEPAGE_GA_TAGS, HOMEPAGE_SECTIONS } from 'Utilities/analytics/analyticsHomePage';

import { RelatedGuides } from 'Storybook/components/local/RelatedGuides';

import { usePageRefEvents } from '../../../common/Hooks/usePageRefEvents';

import LocalTexts from './HomepageRelatedGuidesTexts.json';

import styles from './HomepageRelatedGuides.scss';

type Props = {
  readonly className?: string;
}

const LocalT = new MDText(LocalTexts);
const t = (key: string) => LocalT.translate(key) as string;

const TEXT = {
  ctaText: t('ctaText'),
  title: t('title'),
};

const relatedGuidesData = Object.values(LocalTexts.content);

export const HomepageRelatedGuides = ({ className }: Props) => {
  const onRefTrigger = () => HOMEPAGE_GA_TAGS.SECTION_LOADED(HOMEPAGE_SECTIONS.RELATED_GUIDES);
  const { setRef } = usePageRefEvents({ onRefTrigger });

  return (
    <div ref={(ref) => setRef(ref)} className={cx(styles.component, className)} data-testid='homepageRelatedGuides'>
      <Content>
        <h2 className={styles.heading}>{ TEXT.title }</h2>
        <div className={styles.content}>
          {
            relatedGuidesData.map(({ description, thumbnail, title, url }) => (
              <RelatedGuides
                key={title}
                {...{ description, title, url }}
                imgAlt={thumbnail?.alt}
                imgSrc={`${wordpressImgToImgixURL(thumbnail?.url)}`}
                wrapperClassName={styles.card}
              />
            ))
          }
        </div>
        <Button
          as={BUTTON_AS_TYPE.ANCHOR}
          href='/guides'
          icon='chevron'
          label={TEXT.ctaText}
          variant={VARIANT.TERTIARY}
        />
      </Content>
    </div>
  );
};

import cx from 'classnames';

import styles from './TextWithHighlight.scss';

export type TextWithHighlightProps = {
  content: string;
  highlight: string;
}

export const TextWithHighlight = ({ content, highlight }: TextWithHighlightProps) => {
  const contentParts = content.split(highlight);

  return (
    <>
      <span>{contentParts[0]}</span>
      <span className={styles.highlightWrapper}>
        <span className={cx(styles.highlight)}>
          <span>{highlight}</span>
        </span>
      </span>
      {contentParts[1] && <span>{contentParts[1]}</span>}
    </>
  );
};

import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { VehicleLookupContext } from 'Context/vehicleLookup';

import { HeroInput } from '../../../../../common/Hero/HeroInput/HeroInput';
import { HeroVehicle } from '../../../../../common/Hero/HeroVehicle/HeroVehicle';
import { useHeroRouteChangeHelpers } from '../../../../../common/Hero/Hooks/useHeroRouteChangeHelpers';
import Lock from '../../../../../fastlane/lock/Lock';
import TrustPilotImage from '../../../../../fastlane/TrustPilotImage/TrustPilotImage';
import { HeroProps } from '../../Hero';
import { PATHS } from '../../Hero.constants';
import { HeroContent } from '../HeroContent/HeroContent';

import styles from './HomepageVRM.scss';

type HomepageVRMProps = {
  inProgress?: boolean;
  loadingHandler?: (value: any) => void;
} & HeroProps;

export const HomepageVRM = ({
  inProgress = false,
  loadingHandler,
}: HomepageVRMProps) => {
  const { vehicleLookupState: vehicle } = useContext(VehicleLookupContext);

  const {
    onNotMyCarClick: onNotMyCarClickHandler,
    setMileageUrl,
    showMileage,
  } = useHeroRouteChangeHelpers({ vehicle });

  return (
    <Lock lock={inProgress}>
      <div className={styles.component}>
        { (showMileage)
          ? (<HeroVehicle
            {...{ onNotMyCarClickHandler, vehicle }}
          />)
          : (<HeroContent />) }
        <HeroInput
          className={styles.heroInput}
          setShowMileage={(value) => setMileageUrl(value)}
          {...{
            inProgress,
            loadingHandler,
            showMileage,
            vehicle,
          }}
        />
        <div className={cx(styles.socialValidationSection)}>
          <Link to={PATHS.review}>
            <TrustPilotImage className={styles.trustpilot} />
          </Link>
        </div>
      </div>
    </Lock>
  );
};

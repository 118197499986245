import cx from 'classnames';

import { TextWithHighlight } from '../TextWithHighlight/TextWithHighlight';

import styles from './Heading.scss';

type HeadingProps = {
  readonly center?: boolean;
  readonly highlightedWord?: string;
  readonly subtitle?: string;
  readonly title: string;
}

export const Heading = ({
  center,
  highlightedWord,
  subtitle,
  title,
}: HeadingProps) => (
  <div className={cx(styles.component, { [styles.centered]: center })}>
    <h2 className={styles.heading}>
      {
        highlightedWord ? <TextWithHighlight content={title} highlight={highlightedWord} /> : title
      }
    </h2>
    { subtitle && <p className={styles.subtitle}>{subtitle}</p> }
  </div>
);

import React from 'react';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { Content } from '@motorway/motorway-storybook-cra';

import { wordpressImgToImgixURL } from 'Utilities';
import { HOMEPAGE_GA_TAGS, HOMEPAGE_SECTIONS } from 'Utilities/analytics/analyticsHomePage';

import ResponsiveImage from '../../../../../components/misc/ResponsiveImage/ResponsiveImage';
import { usePageRefEvents } from '../../../common/Hooks/usePageRefEvents';
import { ExpandableSection } from '../../../fastlane/expandableSection/ExpandableSection';
import { FAQImage } from '../../../landingPages/Pages/LandingPage.helpers';

import LocalTexts from './HomepageFAQTexts.json';

import styles from './HomepageFAQ.scss';

type Props = {
  readonly className?: string;
}

const LocalT = new MDText(LocalTexts);
const t = (key: string) => LocalT.translate(key) as string;

const TEXT = {
  imgAlt: t('imgAlt'),
  title: t('title'),
};

const faqContent = Object.values(LocalTexts.content);

export const HomepageFAQ = ({ className }: Props) => {
  const onRefTrigger = () => HOMEPAGE_GA_TAGS.SECTION_LOADED(HOMEPAGE_SECTIONS.FAQ);
  const { setRef } = usePageRefEvents({ onRefTrigger });

  const { imgSrc } = FAQImage;

  const responseImage = (
    <ResponsiveImage
      imgProps={{
        alt: TEXT.imgAlt,
        height: 512,
        loading: 'lazy',
        width: 512,
      }}
      imgSrc={wordpressImgToImgixURL(imgSrc)}
    />
  );

  return (
    <div ref={(ref) => setRef(ref)} className={cx(styles.component, className)} data-testid='homepageFAQ'>
      <Content className={styles.faqContent}>
        <ExpandableSection content={faqContent} imgSrc={responseImage} title={TEXT.title} />
      </Content>
    </div>
  );
};
